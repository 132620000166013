import { useEffect } from "react";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import useModal from "shared/hooks/useModal";
import textualize from "shared/utils/textualize";
import styled from "styled-components";
import { Column, FullHeight } from "../ErrorComponents";
import squeezedAnimation from "./squeezedAnimation";

export const MOBILE_BREAKPOINT = 720;

const ChildrenContainer = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT - 1}px) {
    > * {
      display: none;
    }
  }
`;

const StyledDiv = styled.div`
  p {
    width: 100%;
    text-align: center;
  }

  ${Column} div {
    margin: 0 auto;

    &[role="button"] {
      margin-bottom: -25% !important;
    }
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    > * {
      display: none;
    }
  }
`;

export interface IProps {
  children: React.ReactNode;
}

const SqueezedError = ({ children }: IProps) => {
  const { AnimationComponent: SqueezedErrorAnimation } = useLottieAnimation({
    animation: squeezedAnimation,
  });

  const { closeAllModals } = useModal();

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`);

    const handleMediaChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        closeAllModals();
      }
    };

    mediaQuery.addEventListener("change", handleMediaChange);

    if (mediaQuery.matches) {
      closeAllModals();
    }

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [closeAllModals]);

  return (
    <>
      <StyledDiv>
        <FullHeight>
          <Column>
            {SqueezedErrorAnimation}
            <p>{textualize("error.squeezed.body")}</p>
          </Column>
        </FullHeight>
      </StyledDiv>
      <ChildrenContainer>{children}</ChildrenContainer>
    </>
  );
};

export default SqueezedError;
